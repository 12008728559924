import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Spinner from "react-spinkit";
import Label from "components/Elements/Label";

import { SmallPill } from "components/Post/styles";

import { getAllMarketingCopy } from "api/db";

import { SpinnerContainer, DashboardContainer } from "components/Admin/styles";

export default function Marketing() {
  const [marketingCopy, setMarketingCopy] = useState(null);

  useEffect(() => {
    getAllMarketingCopy().then((snapshot) => {
      setMarketingCopy(snapshot.val());
    });
  }, []);

  return (
    <DashboardContainer>
      <Label>
        {" "}
        <a href="/admin/dashboard"> &larr; Back</a>{" "}
      </Label>
      <h1>Marketing Copy</h1>
      {marketingCopy ? (
        <div className="copyDiv">
          <table>
            <thead>
              <tr>
                <th>Language</th>
                <th>Create Page</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(marketingCopy.language).map((language, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <a href={`/language/${language[0]}`}>
                        {language[1].title}
                      </a>
                    </td>
                    <td>
                      <a>
                        {language[1].createPage ? (
                          <SmallPill c="#7ef29d" small>
                            True
                          </SmallPill>
                        ) : (
                          <SmallPill c="#f7b3b7" small>
                            False
                          </SmallPill>
                        )}
                      </a>
                    </td>
                    <td>
                      <a href={`/copy/language/${language[0]}`}>
                        <SmallPill c="#f2a2dc" small>
                          Edit
                        </SmallPill>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Create Page</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(marketingCopy.category).map((category, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <a href={`/category/${category[0]}`}>
                        {category[1].title}
                      </a>
                    </td>
                    <td>
                      <a>
                        {category[1].createPage ? (
                          <SmallPill c="#7ef29d" small>
                            True
                          </SmallPill>
                        ) : (
                          <SmallPill c="#f7b3b7" small>
                            False
                          </SmallPill>
                        )}
                      </a>
                    </td>
                    <td>
                      <a href={`/copy/category/${category[0]}`}>
                        <SmallPill c="#f2a2dc" small>
                          Edit
                        </SmallPill>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>Difficulty</th>
                <th>Create Page</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(marketingCopy.difficulty).map(
                (difficulty, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <a href={`/difficulty/${difficulty[0]}`}>
                          {difficulty[1].title}
                        </a>
                      </td>
                      <td>
                        <a>
                          {difficulty[1].createPage ? (
                            <SmallPill c="#7ef29d" small>
                              True
                            </SmallPill>
                          ) : (
                            <SmallPill c="#f7b3b7" small>
                              False
                            </SmallPill>
                          )}
                        </a>
                      </td>
                      <td>
                        <a href={`/copy/difficulty/${difficulty[0]}`}>
                          <SmallPill c="#f2a2dc" small>
                            Edit
                          </SmallPill>
                        </a>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <SpinnerContainer>
          <Spinner name="ball-scale-multiple" color="#438cee" />
        </SpinnerContainer>
      )}{" "}
    </DashboardContainer>
  );
}
